import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"

import Termly from "../../components/termly"

import Hero from "../../components/hero"
import Contact from "../../components/contact"
import Layout from "../../components/layout"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms of use" />
    <Hero
      headline={(<span>Terms of use.</span>)}
      dek="We can have nice things! Use our site for good and respect each other's rights."
      readMore="Read the policy"
      readMoreLink="#termly"
    />
    <Termly doc="a298f875-d261-493c-b5f0-28a48f0f921e"/>
  </Layout>
)

export default TermsPage
